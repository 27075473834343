<template>
	<iframe :src="src" width="600" height="450" style="border:0;width:100%;filter:grayscale(50%);-webkit-filter:grayscale(50%)" loading="lazy"></iframe>
</template>

<script>
export default {
	name: 'GoogleMaps',
	props: ['location'],
	components: {

	},
	data() {
		return {
			// src: 'https://www.google.com/maps?q=35.6761919,139.6503106&output=embed'
			src: ''
		}
	},
	mounted() {
		
			this.src = `https://www.google.com/maps?q=${this.location.lat},${this.location.long}&output=embed`
		
	}
}
</script>
