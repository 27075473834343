<template>
	<div class="flex flex-col min-w-300 px-2">		
		
			<img src="http://placehold.jp/300x300.png" alt="">
			<p>{{ place.title }}</p>
			<p>{{ place.description }}</p>
			<button>Google Maps</button>
		
	</div>
</template>

<script>
export default {
	name: 'Card',
	props: ['place']
}
</script>
