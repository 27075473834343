<template>
  <div class="flex flex-wrap">
    <template v-for="place in placeList" :key="place.id">      
    
    <!-- <VerticalCard @grandChildEmit="getGranchChildEmit" :isEnglishMode="isEnglishMode" :place="place" /> -->
    <VerticalCard @grandChildEmit="getGranchChildEmit" :isEnglishMode="isEnglishMode" :place="place" />
    
    </template>
  </div>
</template>

<script>
import VerticalCard from "@/components/verticalcards/VerticalCard";

export default {
  name: 'VerticalCards',
  props: ['placeList', 'isEnglishMode'],
  components: {
    VerticalCard,
  },
  methods: {
    getGranchChildEmit(value) {
      this.$emit('childEmit', value);      
    }
  }
};
</script>
