<template>
	<p>カテゴリー（カフェ）、アクティビティ（遊ぶ）など自由に検索できます。</p>
	<div class="search-examples">
	<p>例: カフェ、コーヒー、寺、川、農場、食べる、体験する、遊ぶ、休む、飲む、泊まる</p>
	</div>
	<input @input="keyword=$event.target.value" :value="keyword" placeholder="フリーワード検索" type="text">	
	<div class="result-counter">検索結果: {{results.length}}件</div>
	<template v-for="(place, index) in results" :key="index">
		<div class="result-item">
			<img :src="place.imgSrc" alt="">
			<strong>{{place.title}}</strong> / {{place.tags}}			
		</div>
	</template>
</template>

<script>
export default {
	name: 'Search',
	props: ['placeList'],
	data: function() {
		return {
			keyword: '',
			results: []
		}
	},
	watch: {
		keyword(value) {
		
			if (value == '') {
				this.results = [];
				return;
			}
			
			this.results = this.placeList.filter((item) => {
				// tags array is joind first and then use indexOf() method
				return (item.title.indexOf(value) !== -1 || item.description.indexOf(value) !== -1 || item.tags.join('').indexOf(value) !== -1);
			})
		}
	}
}
</script>

<style scoped>
input[type="text"] {
	width:100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
	font-size: 20px;
	line-height: 1.5;
	border: 3px solid lightseagreen;
	border-radius: 5px;
}
.result-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
	padding: 10px 15px;
	background: rgb(242, 242, 242);
}
.result-item img {
	margin-right: 10px;
}
.result-counter {
	margin-top: 15px;
	margin-bottom: 15px;
}
.search-examples {
	padding: 10px 15px;
	background: rgb(242, 242, 242);
	margin-bottom: 20px;
}
</style>