<template>
  <div class="flex overflow-x-scroll">
    
    <Card v-for="place in placeList" :key="place.id" :place="place" />
    
  </div>
</template>

<script>
import Card from "@/components/cards/Card";

export default {
  name: 'Cards',
  props: ['placeList'],
  components: {
    Card,
  },
};
</script>
