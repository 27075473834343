<template>
  <div class="bg h-full" :style="bgStyle">
    <div class="bg-overlay md:pb-10 md:pt-10" :style="changeBgColorByTime">
    
      <div class="bg-blur p-8 rounded-lg md:w-2/3 mx-auto">
        <p class="bg-white rounded-lg p-5 text-btn-red">お使いのスマートフォンの位置情報をブラウザに許可していただくと現在地に基づいたコンテンツが表示され、より便利にお使いいただけます。</p>

        <div class="h-20 mt-12 relative">
          <transition name="fade-y" mode="out-in">
            <p v-if="location" class="absolute w-full bg-white rounded-lg flex flex-col items-center py-3">
              <template v-if="northAreaWeatherForecast">
                <div class="absolute -top-12 -left-6">
                  <div class="mx-auto flex flex-col items-center rounded-full bg-white mt-3 relative h-20 w-20">                    
                    <Spinner class="mt-3" v-if="weatherIconLoading" />
                    <img class="mt-3" @load="weatherIconLoading = false" :src="getWeatherIcon" alt="" />
                    <span class="absolute bottom-3 text-center text-black text-xs rounded-sm">竹田の空</span>
                  </div>
                </div>
              </template>
              <img class="w-5" :src="`${baseUrl}/images/icon-pin-01.svg`" />
              位置情報を取得しました。
            </p>
            <p v-else class="absolute w-full bg-white rounded-lg flex flex-col items-center py-3">
              <template v-if="northAreaWeatherForecast">
                <div class="absolute -top-12 -left-6">
                  <div class="mx-auto flex flex-col items-center rounded-full bg-white mt-3 relative h-20 w-20">                    
                    <Spinner class="mt-3" v-if="weatherIconLoading" />
                    <img class="mt-3" @load="weatherIconLoading = false" :src="getWeatherIcon" alt="" />
                    <span class="absolute bottom-3 text-center text-black text-xs rounded-sm">竹田の空</span>
                  </div>
                </div>
              </template>
              <img class="w-5" :src="`${baseUrl}/images/icon-pin-02.svg`" />
              位置情報を取得できません。
            </p>
          </transition>
        </div>

        <transition name="switch" mode="out-in">
          <div v-if="showHome" class="">
            <!-- <Search :placeList="placeList" /> -->
            <div class="mt-12">
              <VerticalCards
                @childEmit="getGrandChildEmit"
                :isEnglishMode="isEnglishMode"
                :placeList="
                  placeList
                    .filter((place) => {
                      return place.tags.join('').indexOf('歴史') !== -1;
                    })
                    .sort((a, b) => {
                      return a.distanceFromHere < b.distanceFromHere ? -1 : 1; // sort A - Z
                    })
                "
              />
            </div>

            <iframe
              src="https://www.google.com/maps/d/embed?mid=1wHKSrNXNozvF1xWnjsZ8Lsj9JlpsAMOJ&ehbc=2E312F"
              width="100%"
              height="480"
              style="border: 0; width: 100%; filter: grayscale(50%); -webkit-filter: grayscale(50%)"
            ></iframe>
          </div>
          <div v-else>
            <div>
              <div class="mt-8">
                <Button
                  @click="
                    () => {
                      showHome = !showHome;
                    }
                  "
                  btnText="一覧へ戻る"
                  needAction="false"
                />
              </div>
              <transition appear @before-enter="beforeEnter" @enter="enter">
                <h2 class="text-2xl md:text-4xl mt-8">
                  {{
                    placeList.find((place) => {
                      return place.id == currentPlaceId;
                    }).title
                  }}
                </h2>
              </transition>
              <div class="content md:p-8 rounded-lg mt-8 p-5">
                <p
                  v-if="
                    placeList.find((place) => {
                      return place.id == currentPlaceId;
                    }).distanceFromHere
                  "
                >
                  現在地から{{
                    placeList.find((place) => {
                      return place.id == currentPlaceId;
                    }).title
                  }}まで、約{{
                    placeList.find((place) => {
                      return place.id == currentPlaceId;
                    }).distanceFromHere
                  }}kmです。
                </p>
                <p>
                  {{
                    placeList.find((place) => {
                      return place.id == currentPlaceId;
                    }).description
                  }}
                </p>
              </div>

              <img
                class="mt-8 rounded-lg"
                :src="
                  placeList.find((place) => {
                    return place.id == currentPlaceId;
                  }).imgSrc
                    ? placeList.find((place) => {
                        return place.id == currentPlaceId;
                      }).imgSrc
                    : 'http://placehold.jp/500x500.png'
                "
                :alt="
                  placeList.find((place) => {
                    return place.id == currentPlaceId;
                  }).title
                "
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/search/Search";
import Geolocation from "@/components/geolocation/Geolocation";
import Cards from "@/components/cards/Cards";
import VerticalCards from "@/components/verticalcards/VerticalCards";
import Button from "@/components/buttons/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { compareAsc, format, isAfter } from "date-fns";
import Spinner from "@/components/loadingicons/Spinner";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Home",
  components: {
    Search,
    Geolocation,
    Cards,
    VerticalCards,
    Button,
    Spinner
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      bgStyle: `
      background: yellow;
      background-image: url("");      
      `,
      changeBgColorByTime: "",
      currentPlaceId: 1,
      hideToLeft: false,
      isEnglishMode: false,
      location: "",
      northAreaWeatherForecast: "",      
      showHome: true,
      showDetail: false,
      placeList: [
        {
          id: 1,
          title: "表米神社",
          titleEn: "this is english title",
          description:
            "表米宿禰命（日下部表米）を祀っている。表米は、丹後国の白井の浜に来襲した新羅の賊を討伐した武人とされる。創建年代は不明であるが、宝永年間に竹田城のある虎臥山東麓の現在地に遷座したと考えられている。本殿は三間社流造で千鳥破風を有しています。なお、神社の本殿欄間及び神門の彫刻は当時、京都一の名工と称された周斎作と伝えられている。",
          descriptionEn: "this is english description",
          tags: ["歩く", "歴史"],
          imgSrc: `${process.env.VUE_APP_BASE_URL}/images/place-01.jpg`,
          location: { lat: 35.2970308, long: 134.832078 },
          distanceFromHere: "",
        },
        {
          id: 2,
          title: "大臣像（表米神社）",
          titleEn: "this is english title",
          description:
            "明治4年6月人形浄瑠璃として有名な淡路源之丞一座が竹田に野上り興行に訪れていた際、火災により小屋・人形・大小の道具に至るまで全てを焼失してしまった。竹田に住む職人らが人形・衣装・舞台のすべてを復元し、その製作期間の寝食を提供した。淡路源之丞一座、御礼興行をうち帰島した。このことを記念し表米神社の通用門を建立し、左右両大臣像をつくり奉祭りしたものがこの大臣像である。",
          descriptionEn: "this is english description",
          tags: ["泊まる", "歴史"],
          imgSrc: `${process.env.VUE_APP_BASE_URL}/images/place-02.jpg`,
          location: { lat: 35.2970308, long: 134.832078 },
          distanceFromHere: "",
        },
        {
          id: 3,
          title: "円形桟敷と土俵と歌舞伎堂（表米神社）",
          titleEn: "this is english title",
          description:
            "江戸時代の三大娯楽の一つであった歌舞伎は「禁止令」が出るなど度々取り締まりの対象となった。相撲は見ると力が湧き農作業などの労働にも意欲が出ると認められていたが、風俗である歌舞伎は庶民が見ることを禁じられた時代もあったそうである。この円形桟敷の前に土俵を挟み歌舞伎堂の配置は、表向き相撲を観覧しているという体裁をとり歌舞伎をこっそりと楽しんだのではないかと言われている。",
          descriptionEn: "this is english description",
          tags: ["食べる", "歴史"],
          imgSrc: `${process.env.VUE_APP_BASE_URL}/images/place-03.jpg`,
          location: { lat: 35.2970308, long: 134.832078 },
          distanceFromHere: "",
        },
        {
          id: 4,
          title: "おかち地蔵",
          titleEn: "this is english title",
          description:
            "昔、迫間路に草庵を結び修行する行心という若僧がいた。そのお経をあげる声に聞き惚れ、その美僧である姿に見惚れ恋慕したのが「お可智」という娘でした。しかしながら行心は仏道に励むそうであり、一緒になることは許されないと知ったお可智は思い余って川に身を投げてしまいました。それを知った町の人は、薄命悲恋の純情を哀れみ、この「おかち地蔵」を祀った。",
          descriptionEn: "this is english description",
          tags: ["叫ぶ", "歴史"],
          imgSrc: `${process.env.VUE_APP_BASE_URL}/images/place-04.jpg`,
          location: { lat: 35.295448, long: 134.838079 },
          distanceFromHere: 0,
        },
        {
          id: 5,
          title: "若松屋久右エ門の架けた石橋",
          titleEn: "this is english title",
          description:
            "宝永元年(1704年）若松屋久右衛門は自費を投じて三年がかりで石橋を架設した。当時、人々の往来の要道であったこの場所は粗末な土橋が架かっており、度々破損し物流などが止まっていたという。この功績を称えた記念碑が今も残る。時代の進歩とともに自動車などが通れるようにとコンクリートの橋へと架け替えられ、現在の場所に移設されるまで、226年間人々の往来を守り続けた。",
          descriptionEn: "this is english description",
          tags: ["叫ぶ", "歴史"],
          imgSrc: `${process.env.VUE_APP_BASE_URL}/images/place-05.jpg`,
          location: { lat: 35.2959597, long: 134.8337753 },
          distanceFromHere: 0,
        },
        {
          id: 6,
          title: "元文一揆の碑",
          titleEn: "this is english title",
          description:
            "元文3年(1738)12月29日、時の代官小林孫四郎の厳しい年貢取り立てに対する郡内(おもに和田山・山東)の農民の不満が爆発し、約3000人が竹田河原に集結して、生野代官所へ押しかけ強訴した。結果、死罪6人流罪8人を含む23人の犠牲者を出して一揆は終結した。死罪となった6人のうち5人の首は竹田川原に晒された。後にその犠牲者の供養のために建てられたのがこの碑であると伝えられる。碑には弘化2年(1845)とあり、100年の後の事である。",
          descriptionEn: "this is english description",
          tags: ["叫ぶ", "歴史"],
          imgSrc: `${process.env.VUE_APP_BASE_URL}/images/place-06.jpg`,
          location: { lat: 35.307213, long: 134.83803 },
          distanceFromHere: 0,
        },
      ],
      todaysWeather: "",
      weatherIconLoading: true,
    };
  },
  methods: {
    /**
     * This function will be excuted when the geolocation is recieved.
     *
     * @param {Object} position - Position from geolocation api
     * @return {Void}
     */
    successFunc(position) {
      this.location = {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      };

      let newPlaceList = [];
      const currentLocation = {
        lat: this.location.lat,
        lon: this.location.long,
      };

      this.placeList.map((place) => {
        const targetLocation = {
          lat: place.location.lat,
          lon: place.location.long,
        };
        place.distanceFromHere = Distance.between(currentLocation, targetLocation).human_readable().distance;
        newPlaceList.push(place);
      });

      // alert(`${this.location.lat} / ${this.location.long}`);
    },

    /**
     * This function will be excuted when failed to get geolocation.
     *
     * @param {Object} error
     * @return {Void}
     */
    errorFunc(error) {
      const errorMessage = {
        0: "原因不明のエラーが発生しました…。",
        1: "位置情報の取得が許可されませんでした…。",
        2: "電波状況などで位置情報が取得できませんでした…。",
        3: "位置情報の取得に時間がかかり過ぎてタイムアウトしました…。",
      };

      console.log(errorMessage[error.code]);
    },

    beforeEnter(el) {
      el.style.transform = "translateY(60px)";
      el.style.opacity = 0;
      console.log("before enter");
    },

    enter(el) {
      gsap.to(el, {
        duration: 1,
        y: 0,
        opacity: 1,
        ease: "ease-out",
      });
      console.log("enter");
    },

    /**
     * Get grand child emit
     */
    getGrandChildEmit(clickedPlaceId) {
      console.log(clickedPlaceId);
      this.showHome = false;
      this.currentPlaceId = clickedPlaceId;
    },
  },
  computed: {
    getWeatherIcon() {
      if (this.northAreaWeatherForecast.forecasts[0].weather.indexOf('晴') !== -1) {
        return `${this.baseUrl}/images/icon-sun.svg`;
      }
      if (this.northAreaWeatherForecast.forecasts[0].weather.indexOf('雨') !== -1) {
        return `${this.baseUrl}/images/icon-rain.svg`;
      }
      if (this.northAreaWeatherForecast.forecasts[0].weather.indexOf('くもり') !== -1) {
        return `${this.baseUrl}/images/icon-cloud.svg`;
      }
      return '';
    }
  },
  mounted() {
    /**
     * Change banckground color by time
     */
    const todayYear = format(new Date(), "yyyy");
    const todayMonth = format(new Date(), "MM") - 1;
    const todayDate = format(new Date(), "dd");

    const morningTime = 1;
    const afterNoonTime = 23;
    const nightTime = 24;

    const morning = new Date(todayYear, todayMonth, todayDate, morningTime);
    const afterNoon = new Date(todayYear, todayMonth, todayDate, afterNoonTime);
    const night = new Date(todayYear, todayMonth, todayDate, nightTime);
    const now = new Date();

    if (isAfter(now, morning)) {
      this.changeBgColorByTime = `
      background-image: url(${process.env.VUE_APP_BASE_URL}/images/bacgrkound-img.png);
      background-color: rgba(237, 229, 221, 1);
      `;
    }
    if (isAfter(now, afterNoon)) {
      this.changeBgColorByTime = `
      background-image: url(${process.env.VUE_APP_BASE_URL}/images/bacgrkound-img.png);
      background-color: rgba(255, 218, 181 ,1);
      `;
    }
    if (isAfter(now, night)) {
      this.changeBgColorByTime = `
      background-image: url(${process.env.VUE_APP_BASE_URL}/images/bacgrkound-img.png);
      background-color: rgba(31, 21, 11, 1);
      `;
    }
    if (!isAfter(now, morning)) {
      this.changeBgColorByTime = `
      background-image: url(${process.env.VUE_APP_BASE_URL}/images/bacgrkound-img.png);
      background-color: rgba(237, 229, 221, 1);
      `;
    }

    /**
     * Get weather forcast
     */
    const hyogoAreaCode = 280000;
    const url = `https://www.jma.go.jp/bosai/forecast/data/forecast/${hyogoAreaCode}.json`;
    fetch(url)
      .then((response) => {
        return response.json(); //ここでBodyからJSONを返す
      })
      .then((result) => {
        const threeDays = result[0];
        const text = threeDays.timeSeries[0];
        let northAreaWeatherForecast;
        const areas = text.areas.map((data) => {
          const forecasts = [];
          for (let i = 0; i < text.timeDefines.length; i++) {
            const forecast = {
              date: text.timeDefines[i],
              weather: data.weathers[i],
              iconUrl: `https://www.jma.go.jp/bosai/forecast/img/${data.weatherCodes[i]}.png`,
              wind: data.winds[i],
              wave: data.waves[i],
            };
            forecasts.push(forecast);
          }

          if (data.area.name == "北部") {
            northAreaWeatherForecast = {
              area: data.area.name,
              forecasts: forecasts,
            };
          }
        });

        this.northAreaWeatherForecast = northAreaWeatherForecast;
        this.todaysWeather = northAreaWeatherForecast.forecasts[0].weather.split("　")[0];
      })
      .catch((e) => {
        console.log(e); //エラーをキャッチし表示
      });

    /**
     * get current geo location
     */
    if (navigator.geolocation) {
      // alert("あなたの端末では、現在位置を取得することができます。");

      this.location = navigator.geolocation.getCurrentPosition(this.successFunc, this.errorFunc, this.geolocationOptions);

      console.log(this.location);
    } else {
      alert("あなたの端末では、現在位置を取得できません。");
    }
  },
};
</script>
<style>
/* Background overlay */
.bg-overlay {
  background: rgba(237, 229, 221, 1);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: contain;
}
.bg-overlay-opacity-0 {
  background: rgba(0, 0, 0, 0.7);
  transition-duration: 3s;
}
.bg-blur {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.content {
  background: white;
}
</style>
