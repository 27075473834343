<template>
  <h2>Geolocations</h2>
  
  <Button
          @click="getCurrentLocation"
          btnText="現在地を取得"
          needAction="false"
        />
  <template v-if="location">
    {{location.lat}}
	  {{location.long}}
  
    <GoogleMaps :location="location"/>
  </template>
  
</template>

<script>
import GoogleMaps from '@/components/googlemaps/GoogleMaps';
import Button from "@/components/buttons/Button";

export default {
  name: "Geolocation",
  components: {
    GoogleMaps,
    Button
  },
  data() {
    return {
      location: "",
			geolocationOptions: {
				"enableHighAccuracy": false ,
				"timeout": 8000 ,
				"maximumAge": 5000 ,
			}
    };
  },
  methods: {
    getCurrentLocation() {
    
      if (navigator.geolocation) {    
        alert("あなたの端末では、現在位置を取得することができます。");

        this.location = navigator.geolocation.getCurrentPosition(this.successFunc, this.errorFunc, this.geolocationOptions);
      }
      else {
        alert("あなたの端末では、現在位置を取得できません。");
      }
    },

    successFunc(position) {			
			this.location = {
				// 緯度
				lat: position.coords.latitude,
				// 経度
				long: position.coords.longitude
			}
      console.log(this.location);
    },

    errorFunc(error) {
      
      const errorMessage = {
        0: "原因不明のエラーが発生しました…。",
        1: "位置情報の取得が許可されませんでした…。",
        2: "電波状況などで位置情報が取得できませんでした…。",
        3: "位置情報の取得に時間がかかり過ぎてタイムアウトしました…。",
      };

      console.log(errorMessage[error.code]);
    },
  },
};
</script>


