import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Detail from '../views/Detail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/places/detail/:id',
    name: 'Detail',
    component: Home
  },
  /* {
    path: '/places/detail/:id',
    name: 'Detail',
    component: Detail
  }, */
  /* {
    path: '/:pathMatch(.*)', component: Home
  } */
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

export default router
