<template>
  <div :style="`height: ${windowHeight}`" class="relative">
    <!-- For mobile device -->
    <transition name="fade-in-slow lg:hidden">
      <div :style="`height: ${windowHeight}`" class="mainvisual absolute top-0 left-0 w-full">
        <!-- Back -->
        <!-- Right top triangles -->
        <div :style="`top: 10%;`" class="absolute right-5 md:right-10 flex flex-col items-center">
          <div class="flex justify-center">                                    
            <div class="triangle triangle-sm triangle-transparent"></div>
            <div class="triangle triangle-sm"></div>            
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm"></div>            
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm triangle-dark-green"></div>            
          </div>
        </div>
        <img :style="`top: 5%;`" class="w-24 xl:w-60 absolute left-1 md:left-32" :src="`${baseUrl}/images/architecture-01.svg`" alt="" />
        <img :style="`top: 15%;`" class="w-24 xl:w-60 absolute right-10 md:right-1/4" :src="`${baseUrl}/images/bridge-01.svg`" alt="" />
        <img :style="`top: 30%;`" class="w-24 xl:w-56 absolute left-16 md:left-1/4" :src="`${baseUrl}/images/architecture-02.svg`" alt="" />
        
        <!-- Middle -->                          
        <img :style="`top: 40%;`" class="w-24 xl:w-60 absolute right-1 md:right-40" :src="`${baseUrl}/images/statue-01.svg`" alt="" />
        <!-- Left middle triangles -->
        <div :style="`bottom: 35%;`" class="absolute left-0 flex flex-col items-center lg:hidden">
          <div class="flex justify-center">                        
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
        </div>
                
        <!-- Front -->                          
        <img :style="`top: 70%;`" class="w-24 xl:w-60 absolute left-10 md:left-1/4" :src="`${baseUrl}/images/architecture-03.svg`" alt="" />
        <img :style="`top: 65%;`" class="w-24 xl:w-60 absolute right-10 md:right-1/4" :src="`${baseUrl}/images/architecture-04.svg`" alt="" />
        <img :style="`bottom: 0%;`" class="w-10 xl:w-20 absolute left-3 md:left-36" :src="`${baseUrl}/images/statue-02.svg`" alt="" />
        <!-- Right bottom triangles -->
        <div :style="`bottom: 0%;`" class="absolute bottom-20 right-0 flex flex-col items-center lg:hidden">
          <div class="flex justify-center">            
            <div class="triangle triangle-lg triangle-transparent"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg triangle-dark-green"></div>
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-lg triangle-dark-green"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg triangle-pink"></div>            
          </div>
        </div>        

        <div v-show="!logoImgLoading" :style="`height: ${windowHeight}`" class="absolute top-0 left-0 w-full flex justify-center items-center">
          <img @load="logoImgLoading = false" :src="`${baseUrl}/images/logo-01.svg`" alt="" />
        </div>

        <div id="leaves" class="absolute top-0">
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />

          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />

          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
        </div>
      </div>
    </transition>
    <!-- For desktop device -->
    <transition name="fade-in-slow hidden lg:block">
      <div :style="`height: ${windowHeight}`" class="mainvisual absolute top-0 left-0 w-full hidden lg:block">
        <!-- Back -->
        <!-- Right top triangles -->
        <div :style="`top: calc(10% + ${scrollY * 0.6}px)`" class="absolute right-5 md:right-10 flex flex-col items-center">
          <div class="flex justify-center">                                    
            <div class="triangle triangle-sm triangle-transparent"></div>
            <div class="triangle triangle-sm"></div>            
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm"></div>            
            <div class="triangle triangle-sm"></div>
            <div class="triangle triangle-sm triangle-dark-green"></div>            
          </div>
        </div>
        <img :style="`top: calc(5% + ${scrollY * 0.6}px)`" class="w-24 xl:w-60 absolute left-1 md:left-32" :src="`${baseUrl}/images/architecture-01.svg`" alt="" />
        <img :style="`top: calc(15% + ${scrollY * 0.6}px)`" class="w-24 xl:w-60 absolute right-10 md:right-1/4" :src="`${baseUrl}/images/bridge-01.svg`" alt="" />
        <img :style="`top: calc(30% + ${scrollY * 0.4}px)`" class="w-24 xl:w-56 absolute left-16 md:left-1/4" :src="`${baseUrl}/images/architecture-02.svg`" alt="" />
        
        <!-- Middle -->                          
        <img :style="`top: calc(40% + ${scrollY * 0.2}px)`" class="w-24 xl:w-60 absolute right-1 md:right-40" :src="`${baseUrl}/images/statue-01.svg`" alt="" />
        <!-- Left middle triangles -->
        <div :style="`bottom: calc(35% - ${scrollY * 0.2}px)`" class="absolute left-0 flex flex-col items-center lg:hidden">
          <div class="flex justify-center">                        
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
        </div>
        <!-- lg size -->
        <div :style="`bottom: calc(35% - ${scrollY * 0.2}px)`" class="absolute left-0 flex-col items-center hidden lg:flex">
          <div class="flex justify-center">                        
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
          <div class="flex justify-center">
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md triangle-transparent"></div>
            <div class="triangle triangle-md"></div>
            <div class="triangle triangle-md triangle-dark-green"></div>            
          </div>
        </div>
        
        <!-- Front -->                          
        <img :style="`top: calc(70% + ${scrollY * 0.1}px)`" class="w-24 xl:w-60 absolute left-10 md:left-1/4" :src="`${baseUrl}/images/architecture-03.svg`" alt="" />
        <img :style="`top: calc(65% + ${scrollY * 0.1}px)`" class="w-24 xl:w-60 absolute right-10 md:right-1/4" :src="`${baseUrl}/images/architecture-04.svg`" alt="" />
        <img :style="`bottom: calc(0% - ${scrollY * 0}px)`" class="w-10 xl:w-20 absolute left-3 md:left-36" :src="`${baseUrl}/images/statue-02.svg`" alt="" />
        <!-- Right bottom triangles -->
        <div :style="`bottom: calc(0% + ${scrollY * 0}px)`" class="absolute bottom-20 right-0 flex flex-col items-center lg:hidden">
          <div class="flex justify-center">            
            <div class="triangle triangle-lg triangle-transparent"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg triangle-dark-green"></div>
          </div>
          <div class="flex justify-center">            
            <div class="triangle triangle-lg triangle-dark-green"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg triangle-pink"></div>            
          </div>
        </div>        
        <!-- lg size -->
        <div :style="`bottom: calc(0% + ${scrollY * 0}px)`" class="hidden absolute bottom-20 right-0 flex-col items-center lg:flex">
          <div class="flex justify-center">
            <div class="triangle triangle-lg triangle-transparent"></div>            
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg triangle-dark-green"></div>
          </div>
          <div class="flex justify-center">
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg triangle-dark-green"></div>
            <div class="triangle triangle-lg"></div>
            <div class="triangle triangle-lg triangle-dark-green"></div>
            <div class="triangle triangle-lg triangle-pink"></div>
            <div class="triangle triangle-lg"></div>            
          </div>
        </div>

        <div v-show="!logoImgLoading" :style="`height: ${windowHeight}`" class="absolute top-0 left-0 w-full flex justify-center items-center">
          <img @load="logoImgLoading = false" :src="`${baseUrl}/images/logo-01.svg`" alt="" />
        </div>

        <div id="leaves" class="absolute top-0">
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />

          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />

          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
          <img :src="`${baseUrl}/images/icon-cherryblossom-01.svg`" alt="" />
        </div>
      </div>
    </transition>
  </div>

  <router-view v-slot="{ Component }">
    <component :is="Component"></component>
  </router-view>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: `${process.env.VUE_APP_BASE_URL}`,
      logoImgLoading: true,
      windowHeight: 0,
      windowWidth: 0,
      scrollY: 0,
    };
  },
  mounted() {
    // window size
    this.windowHeight = window.innerHeight + "px";
    this.windowWidth = window.innerWidth + "px";

    // scroll event listener
    window.addEventListener("scroll", this.getScrollEventListener);
    // window resize event litener
    window.addEventListener("resize", this.widnowResizeEventeListener);        
  },
  methods: {
    getScrollEventListener() {
      this.scrollY = window.scrollY;
    },
    widnowResizeEventeListener() {
      if (this.windowWidth > 768) {
        this.windowHeight = window.innerHeight + "px";
        this.windowWidth = window.innerWidth + "px";
      }
    },    
  },
};
</script>

<style scoped>
.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 150px 100px;
  border-color: transparent transparent #089e00 transparent;
}
.triangle-sm {  
  opacity: .3;
  border-width: 0 30px 45px 30px;  
}
.triangle-md {
  opacity: .6;  
  border-width: 0 50px 75px 50px;  
}
.triangle-lg {  
  border-width: 0 100px 150px 100px;  
}
@media screen and (max-width: 1024px) {
.triangle-lg {  
  border-width: 0 70px 105px 70px;
}
}
@media screen and (max-width: 767px) {
 .triangle-sm {  
  opacity: .3;
  border-width: 0 20px 30px 20px;  
}
.triangle-md {
  opacity: .6;  
  border-width: 0 30px 45px 30px;  
}
.triangle-lg {  
  border-width: 0 40px 60px 40px;  
} 
}
.triangle-dark-green {
  border-color: transparent transparent #045900 transparent;
}
.triangle-pink {
  border-color: transparent transparent #ffbbef transparent;
}
.triangle-transparent {
  border-color: transparent transparent transparent transparent;
}
.mainvisual {
  background-color: #efe4d8;
  background-image: url("/images/bg-pattern.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.fade-in-slow-enter-from {
  opacity: 0;
  will-change: transform;
}
.fade-in-slow-leave-to {
  opacity: 0;
  will-change: transform;
}
.fade-in-slow-enter-active,
.fade-in-slow-leave-active {
  will-change: transform;
  transition: all 2s ease-out;
}

/* leaf animations */
#leaves {
  position: relative;
  top: -50px;
  width: 100%;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #leaves {
    top: -100px;
  }
}

#leaves img {
  display: inline-block;
  width: 200px;
  height: 150px;
  /* background: linear-gradient(to bottom right, #309900, #005600); */
  /* background-image: url("/images/icon-cherryblossom-01.svg"); */
  background-repeat: no-repeat;
  transform: skew(20deg);
  /*  border-radius: 5% 40% 70%;
    box-shadow: inset 0px 0px 1px #222;
    border: 1px solid #333; */
  z-index: 1;
  -webkit-animation: falling 5s 0s infinite;
}

#leaves img:nth-of-type(2n) {
  -webkit-animation: falling2 5s 0s infinite;
}
#leaves img:nth-of-type(3n) {
  -webkit-animation: falling3 5s 0s infinite;
}

#leaves img:before {
  position: absolute;
  content: "";
  top: 117px;
  right: 9px;
  height: 27px;
  width: 32px;
  transform: rotate(49deg);
  /* border-radius: 0% 15% 15% 0%;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 0px solid #222;
  border-right: 1px solid #222;
  background: linear-gradient(to right, rgba(0,100,0,1), #005600); */
  /* background-image: url("/images/icon-cherryblossom-01.svg"); */
  background-repeat: no-repeat;
  z-index: 1;
}

#leaves img:after {
  content: "";
  height: 125px;
  width: 10px;
  /* background: linear-gradient(to right, rgba(0,0,0,.15), rgba(0,0,0,0)); */
  display: block;
  transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius: 50%;
}

#leaves img:nth-of-type(n) {
  height: 23px;
  width: 30px;
}
#leaves img:nth-of-type(n):before {
  width: 7px;
  height: 5px;
  top: 17px;
  right: 1px;
}
#leaves img:nth-of-type(n):after {
  width: 2px;
  height: 17px;
  left: 12px;
  top: 0px;
}

#leaves img:nth-of-type(2n + 1) {
  height: 11px;
  width: 16px;
}
#leaves img:nth-of-type(2n + 1):before {
  width: 4px;
  height: 3px;
  top: 7px;
  right: 0px;
}
#leaves img:nth-of-type(2n + 1):after {
  width: 2px;
  height: 6px;
  left: 5px;
  top: 1px;
}

#leaves img:nth-of-type(3n + 2) {
  height: 17px;
  width: 23px;
}
#leaves img:nth-of-type(3n + 2):before {
  height: 4px;
  width: 4px;
  top: 12px;
  right: 1px;
}
#leaves img:nth-of-type(3n + 2):after {
  height: 10px;
  width: 2px;
  top: 1px;
  left: 8px;
}

#leaves img:nth-of-type(n) {
  -webkit-animation-delay: 1.9s;
}
#leaves img:nth-of-type(2n) {
  -webkit-animation-delay: 3.9s;
}
#leaves img:nth-of-type(3n) {
  -webkit-animation-delay: 2.3s;
}
#leaves img:nth-of-type(4n) {
  -webkit-animation-delay: 4.4s;
}
#leaves img:nth-of-type(5n) {
  -webkit-animation-delay: 5s;
}
#leaves img:nth-of-type(6n) {
  -webkit-animation-delay: 3.5s;
}
#leaves img:nth-of-type(7n) {
  -webkit-animation-delay: 2.8s;
}
#leaves img:nth-of-type(8n) {
  -webkit-animation-delay: 1.5s;
}
#leaves img:nth-of-type(9n) {
  -webkit-animation-delay: 3.3s;
}
#leaves img:nth-of-type(10n) {
  -webkit-animation-delay: 2.5s;
}
#leaves img:nth-of-type(11n) {
  -webkit-animation-delay: 1.2s;
}
#leaves img:nth-of-type(12n) {
  -webkit-animation-delay: 4.1s;
}
#leaves img:nth-of-type(13n) {
  -webkit-animation-delay: 1s;
}
#leaves img:nth-of-type(14n) {
  -webkit-animation-delay: 4.7s;
}
#leaves img:nth-of-type(15n) {
  -webkit-animation-delay: 3s;
}

/* 
#leaves i:nth-of-type(n)    { background: linear-gradient(to bottom right, #309900, #005600); }
#leaves i:nth-of-type(2n+2)  { background: linear-gradient(to bottom right, #5e9900, #2b5600); }
#leaves i:nth-of-type(4n+1)  { background: linear-gradient(to bottom right, #990, #564500); }
 */
#leaves img:nth-of-type(n) {
  opacity: 1;
}
#leaves img:nth-of-type(3n + 1) {
  opacity: 0.7;
}
#leaves img:nth-of-type(3n + 2) {
  opacity: 0.5;
}

#leaves img:nth-of-type(n) {
  transform: rotate(180deg);
}

#leaves img:nth-of-type(n) {
  -webkit-animation-timing-function: ease-in-out;
}

@-webkit-keyframes falling {
  0% {
    -webkit-transform: translate3d(300, 0, 0) rotate(0deg);
  }

  100% {
    -webkit-transform: translate3d(-350px, 700px, 0) rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(-20deg);
  }

  100% {
    -webkit-transform: translate3d(-230px, 640px, 0) rotate(-70deg);
    opacity: 0;
  }
}

@-webkit-keyframes falling2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(90deg);
  }

  100% {
    -webkit-transform: translate3d(-400px, 680px, 0) rotate(0deg);
    opacity: 0;
  }
}
</style>
