<template>
	<div @click="btnClickAction" class="rounded-full bg-btn-red w-52 text-center h-10 leading-10 text-white cursor-pointer">{{ btnText }}</div>
</template>
<script>
export default {
	name: 'Button',
	props: ['btnText', 'routeTo', 'needAction'],
	methods: {		
		btnClickAction() {
			if (this.routeTo == false) {
				return
			}

			if (this.needAction == false) {
				return
			}

			// this.$router.go(this.routeTo);
		}
	}
}
</script>
