<template>
  <!-- <div @click="$emit('grandChildEmit', place.id)" class="flex flex-col md:w-1/2 lg:w-1/3 md:px-2"> -->
  <div @click="$emit('grandChildEmit', place.id)" class="relative flex flex-col md:w-1/2 lg:w-1/3 md:px-2 mb-14 cursor-pointer">
    <!-- <router-link :to="{ name: 'Detail', params: { id: place.id } }"> -->

      <img        
        class="object-cover h-60 w-full rounded-lg"
        :src="place.imgSrc ? place.imgSrc : 'http://placehold.jp/300x300.png'"
        alt=""
      />

      <p class="mt-3 text-xl">{{ !isEnglishMode ? place.title : place.titleEn }}</p>
      
      <transition name="fade" mode="out-in">              
      <div class="absolute -top-12 -left-6">
        <div class="mx-auto flex flex-col items-center rounded-full bg-white mt-3 relative h-20 w-20">
          <div v-if="loading">
            <Spinner />
          </div>
          
            <img @load="loading = false" class="h-10 mt-3" :src="personIconList[place.id - 1]" alt="">
          
          <span v-if="place.distanceFromHere" class="absolute bottom-3 text-center text-black text-xs rounded-sm">{{ place.distanceFromHere }} km</span>
          <span v-else class="absolute bottom-3 text-center text-black text-xs rounded-sm">...</span>
        </div>
      </div>
      </transition>

      <!-- <div class="flex justify-center">
        <Button btnText="Google Maps" needAction="false" />
      </div> -->
    <!-- </router-link> -->
  </div>
</template>

<script>
import Button from "@/components/buttons/Button";
import Spinner from "@/components/loadingicons/Spinner";

export default {
  name: "VerticalCard",
  components: {
    Button,
    Spinner,
  },
  props: ["place", "isEnglishMode"],
  data() {
    return {
      loading: true,
      personIconList: [
        `${process.env.VUE_APP_BASE_URL}/images/icon-person-01.svg`,
        `${process.env.VUE_APP_BASE_URL}/images/icon-person-02.svg`,
        `${process.env.VUE_APP_BASE_URL}/images/icon-person-03.svg`,
        `${process.env.VUE_APP_BASE_URL}/images/icon-person-04.svg`,
        `${process.env.VUE_APP_BASE_URL}/images/icon-person-05.svg`,
        `${process.env.VUE_APP_BASE_URL}/images/icon-person-06.svg`,
      ],
      href: `https://www.google.com/maps/search/${this.place.location.lat},${this.place.location.long},17z`,
    };
  },
};
</script>
<style scoped>
/* Fade animation */
.fade-enter-from {
	opacity: 0;
  /* transform:rotateY(5000deg); */
  -webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.fade-leave-to {
	opacity: 0;
  -webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.fade-enter-active,
.fade-leave-active {
  will-change: transform;
	transition: all .5s ease-out;
}

.object-cover {
  width: 100%;
}
</style>